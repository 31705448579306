<template>
  <div class="chart">
    <div class="leftbox">
			<div class="buttonBox"></div>
      <div class="tableBox">
        <el-table border :data="tableData" highlight-current-row row-key="id" height="100%" style="width: 100%"
          :tree-props="{ children: 'children' }" @row-click="rowClick">
          <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
          <el-table-column prop="dsName" label="数据集名称" align="center"></el-table-column>
          <el-table-column prop="connName" label="数据库连接" align="center"></el-table-column>
          <el-table-column prop="dsType" label="数据集类型" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="rightbox">
      <div class="buttonBox">
        <el-button size="small" type="primary" @click="addtable">新建图表</el-button>
        <el-button size="small" type="danger" @click="datildeletecart">删除图表</el-button>
      </div>
      <div class="tableBox">
        <el-table border :data="tableDataname" style="width: 100%" height="100%" @selection-change="ditallistSelectionChange">
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
          <el-table-column prop="catName" label="图表名称" align="center"></el-table-column>
          <el-table-column label="操作" width="120" align="center">
            <template slot-scope="scope">
              <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { deleteLargeCategory } from "@/api/chart/index.js";
import { getdataSetlist, getChartData } from "@/api/dataSet/index.js";
export default {
  name: "chart",
  data() {
    return {
      tableData: [],
      tableDataname: [],
      formInline: {
        catName: "",
        catType: 0,
        children: [],
      },
      tableids: "",
      ditaldata: [],
    };
  },
  mounted() {
    this.init();
  },
	methods: {
		// 初始化数据
    init() {
      /* 图表打类改成数据集 */
      getdataSetlist().then((res) => {
        this.tableData = res;
      });
		},

		// 数据集行触发事件
		rowClick(row, column, event) {
      console.log(row)
      this.tableids = row.id;
      getChartData({ datasetId: row.id }).then((res) => {
        this.tableDataname = res;
      });
		},

		// 图表新建图表按钮
    addtable() {
      if (this.tableids == "" || this.tableids == null || undefined) {
        this.$message({ message: "请先选择数据", type: "warning" });
      } else {
        this.$router.push({
          name: "setTable",
          params: { id: this.tableids },
        });
      }
		},

		// 图表多选事件
    ditallistSelectionChange(selection) {
      this.ditaldata = [];
      for (let i = 0; i < selection.length; i++) {
        this.ditaldata.push(selection[i].id);
      }
		},

		// 多选删除图表按钮
    datildeletecart() {
      deleteLargeCategory(this.ditaldata).then((res) => {
        for (let i = 0; i < this.ditaldata.length; i++) {
          for (let j = 0; j < this.tableDataname.length; j++) {
            if (this.ditaldata[i] == this.tableDataname[j].id) {
              this.tableDataname.splice(j, 1);
            }
          }
        }
      });
		},

		// 图表行编辑事件
    handleEdit(row) {
      this.$router.push({
        name: "addTable",
        params: {
          id: row.id,
          dataId: row.datasetId,
          name: row.catName,
          heightList: row.canvasHeight,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.chart {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
}
.leftbox {
  width: 40%;
  height: 100%;
  float: left;
  border-right: 1px solid black;
}
.rightbox {
  width: 59%;
  height: 100%;
  float: left;
}
.buttonBox {
  width: 100%;
  padding: 10px;
  min-height: 60px;
  box-sizing: border-box;
}
.tableBox {
  height: 85%;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}
</style>
